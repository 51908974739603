import React from 'react'
import {Link} from 'react-router-dom'

function LoginPg() {
  return (
    <> 

      <div className='Space1'></div>

    
     <div className=' LoginBg'> 
     
      <div className='LoginDiv'> 

       
          <p className='LoginPgHead'> Open only for early access users currently. 
            <p className='LoginPgApplyHead'> Apply through writing a message on our Linkedin page for early access </p> <br />
            <Link className='LoginPgApplyBt' to="https://www.linkedin.com/company/bhooom/"> apply </Link>

          </p>
          
         

         

      </div>

    </div>

    </>
  )
}

export default LoginPg