import React from 'react'
import { Link } from 'react-router-dom'

import './Styles.css'
import ReChargeIcon from './Images/ReChargeIcon.svg'
import FoodsIcon from './Images/FoodsIcon.svg'
import eRidesIcon from './Images/eRidesIcon.svg'
import PayIcon from './Images/PayIcon.svg'
import OneIcon from './Images/OneIcon.svg'
import WaterIcon from './Images/WaterIcon.svg'
import ReCycleIcon from './Images/ReCycleIcon.svg'
import InvestEarnIcon from './Images/InvestEarnIcon.svg'
import ManageChargersIcon from './Images/ManageChargersIcon.svg'




function Home() {
  return (
  

   <>

    <div className='GreenbgDiv'> 
   <div className='HomeBg  '>
       
            {/* <p className='HomeHead'> Building the future of e-mobility </p> */}

            <div className='HomeDispMenu  '>

                <Link to="/foods ">  <div className='HomeDispMenuStyle '> 
                <div> <img className="HomeIcons" src={FoodsIcon} /> </div>
                <p className='MenuHead'> Foods</p>
                </div> </Link>

                <Link to="/ "> <div className='HomeDispMenuStyle '> 
                <div> <img className="HomeIcons" src={ReChargeIcon} /> </div>
                <p className='MenuHead'> ReCharge </p>
                </div> </Link>

                <Link to="/"> <div className='HomeDispMenuStyle '> 
                <div> <img className="HomeIcons" src={eRidesIcon} /> </div>
                <p className='MenuHead'> eRides </p>
                </div> </Link>


                <Link to="/"> <div className='HomeDispMenuStyle '> 
                <div> <img className="HomeIcons" src={PayIcon} /> </div>
                <p className='MenuHead'> Pay </p>
                </div> </Link>

                 <Link to="/"> <div className='HomeDispMenuStyle '> 
                 <div> <img className="HomeIcons" src={OneIcon} /> </div>
                <p className='MenuHead'> One </p>
                </div> </Link>

                {/* <Link to="/"> <div className='HomeDispMenuStyle '> 
                <div> <img className="HomeIcons" src={WaterIcon} /> </div>
                <p className='MenuHead'> Water </p>
                </div> </Link> */}

                 <Link to="/"> <div className='HomeDispMenuStyle '> 
                 <div> <img  className="HomeIcons" src={ReCycleIcon} /> </div>
                <p className='MenuHead'> eReCycle </p>
                </div> </Link>

                <Link to="/"> 
                <div className='HomeDispMenuStyle '> 
                 <div> <img  className="HomeIcons" src={InvestEarnIcon} /> </div>
                <p className='MenuHead'> Invest&Earn </p>
                </div> </Link>

                <Link to="/"> 
                <div className='HomeDispMenuStyle '> 
                 <div> <img  className="HomeIcons" src={ManageChargersIcon} /> </div>
                <p className='MenuHead'> Manage Chargers </p>
                </div> </Link>


                

                


            </div>
    
   </div>
   </div>

   {/* <div className='FillerDiv'>  



   </div> */}

   </>

  
  )
}

export default Home