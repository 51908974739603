import React from 'react'
import './Styles.css'
import {Link} from 'react-router-dom'

function LoginBt() {
  return (


    <>     

     {/* <div className='LoginBt'> */}
            <Link className="LoginBt" to="/login"> login </Link>
    {/* // </div> */}

    </>


  )
}

export default LoginBt