
import './App.css';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Header from './components/Header/Header'
import Home from './components/pages/Home/Home';
import LoginPg from './components/Login/LoginPg';

import Foods from './components/pages/Foods/Foods.js'
// import Foods from './components/pages/Foods/Foods.js'


function App() {
  return (
    <div className="App"> 

        <Router>
          
          <Header />

            <Routes> 
              
              <Route exact path='/' element={<Home />} />  
              <Route exact path='/login' element={<LoginPg />} />
              <Route exact path='/foods' element={<Foods />} />  

              {/* <Route exact path='/foods' element={<Water />} />  
              <Route exact path='/foods' element={<ReCycle />} />  
              <Route exact path='/foods' element={<ReCharge />} />  
              <Route exact path='/foods' element={<Pay />} />  
              <Route exact path='/foods' element={<One />} />  
              <Route exact path='/foods' element={<Foods />} />   */}

              
  
            </Routes>

      </Router>


    </div>
  );
}

export default App;
