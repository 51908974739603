import React from 'react'
import './Styles.css'

function Foods() {
  return (
    <>
        
       <div> 

       <div className='SearchBarDiv'> 

            <div className='SearchBarFoodsPg'> 
                Search from the healthy options below </div>
             </div>

        <div className='AllFoodsDiv'> 

        

        <div className=' Head CardFoodItems'>   Fresh Breads </div> 

        <div className=' Head CardFoodItems'>  Lunches </div> 

        <div className=' Head CardFoodItems'>  Drinks </div> 

        <div className=' Head CardFoodItems'>  Healthy Foods </div>

        </div>
        </div>



        
    </>
  )
}

export default Foods