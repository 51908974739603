import React from 'react'
import Logo from '../../Logos/BhooomLogo.svg'
import './Styles.css'
import {Link} from 'react-router-dom'
import LoginBt from '../Login/LoginBt'

function Header() {
  return (

    <div className='HeaderTop'>
           
           {/* <Link to="/">  */}
            <div className='LogoCont'> 
                {/* <img alt="" className='Logo' src={Logo} fill="white" color="white" />  */}
                <p className='Logo'>  BHOOOM</p>
             </div> 
             {/* </Link> */}
           
           <LoginBt />
           
            

     </div>

  )
}

export default Header